import { BsInfoCircleFill } from 'react-icons/bs'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

interface ErrorDialogProps {
  title: string
  contents: string
  show: boolean
  onOk?: () => void
}
/**
 *
 * @returns
 */
const NotifyDialog = ({ title, contents, show, onOk }: ErrorDialogProps) => {
  /**
   *
   */
  const OK = () => {
    if (onOk) onOk()
  }
  const isContentsMultiline = contents.includes('\n')
  const multiline = isContentsMultiline ? contents.split('\n') : []

  return (
    <div>
      <Dialog
        open={show}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BsInfoCircleFill
              style={{ color: '#0000cd', width: '30px', height: '30px', marginRight: '10px' }}
            />
            {title}
          </div>
        </DialogTitle>
        <DialogContent>
          {!isContentsMultiline && (
            <DialogContentText id="alert-dialog-description">{contents}</DialogContentText>
          )}
          {isContentsMultiline && (
            <DialogContentText id="alert-dialog-description">
              {multiline.map((line, index) => (
                <>
                  {index !== 0 && <br />}
                  <span>{line}</span>
                </>
              ))}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={OK}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default NotifyDialog
