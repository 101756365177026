import { sendInquiry } from 'api/inquiry'

import { useGlobalState } from 'components/common/provider/GlobalStateProvider'
import { useState, createContext, useContext } from 'react'
import { ListElement } from 'types/common/ListElement'
import { ProviderProps } from 'types/common/ProviderProps'
import { InquiryInfo } from 'types/infos/InquiryInfo'
import { ABOUT_ARRAY, RESPONSE_OK } from 'utils/constants'
import {
  COMMON_ERROR_MESSAGE,
  CONTAIN_ERROR_TITLE,
  emailRegexpMessage,
  FATAL_ERROR_MESSAGE,
  FATAL_ERROR_TITLE,
  lengthLessThanMessage,
  numCharsOnlyMessage,
  requiredMessage
} from 'utils/messageUtil'
import {
  hasNotValue,
  hasValue,
  invalidMailAddress,
  invalidMaxLength,
  invalidNumChars,
  isInteger
} from 'utils/validators'

interface InquiryStateProps {
  aboutList: ListElement[]

  email: string
  aboutString: string
  title: string
  detail: string
  name: string
  corpName: string
  tel: string
  isAgreePrivatePolicy: boolean
  isEnableSend: boolean

  msg4Email: string
  msg4About: string
  msg4Title: string
  msg4Detail: string
  msg4Name: string
  msg4CorpName: string
  msg4Tel: string

  getAboutAsString: () => string
  changeEmail: (val: string) => void
  changeAboutString: (val: string) => void
  changeTitle: (val: string) => void
  changeDetail: (val: string) => void
  changeName: (val: string) => void
  changeCorpName: (val: string) => void
  changeTel: (val: string) => void
  changeIsAgreePrivatePolicy: (val: boolean) => void

  dialogTitle: string
  dialogContents: string
  showErrorDialog: boolean
  showConfirmDialog: boolean
  showNotifyDialog: boolean

  closeErrorDialog: () => void
  closeConfirmDialog: () => void
  closeNotifyDialog: () => void

  checkInquiry: () => boolean
  doInquiry: () => Promise<boolean>
  clear: () => void
  clearMsg: () => void
}

/**
 * 「お問合せ」処理グローバルStateContext.
 * ユーザー入力値とその操作は、ここに格納される。
 */
export const InquiryStateContext = createContext({} as InquiryStateProps)

/**
 * 「お問合せ」処理グローバルStateを外部に公開するためのfunction.
 * @returns InquiryStatePropsインスタンス
 */
export const useInquiryState = () => useContext(InquiryStateContext)

/**
 * 「お問合せ」処理グローバルStateProvider.
 * @param props ProviderProps
 * @returns
 */
export const InquiryStateProvider = (props: ProviderProps) => {
  // 呼出元から渡される本Providerタグで囲まれたタグ群
  const { children } = props
  const gStates = useGlobalState()

  const [aboutList] = useState<ListElement[]>(ABOUT_ARRAY)

  const [email, setEmail] = useState<string>('')
  const [about, setAbout] = useState<number | null>(0)
  const [title, setTitle] = useState<string>('')
  const [detail, setDetail] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [corpName, setCorpName] = useState<string>('')
  const [tel, setTel] = useState<string>('')
  const [isAgreePrivatePolicy, setIsAgreePrivatePolicy] = useState<boolean>(false)
  const [isEnableSend, setIsEnableSend] = useState<boolean>(false)

  const [msg4Email, setMsg4Email] = useState<string>('')
  const [msg4About, setMsg4About] = useState<string>('')
  const [msg4Title, setMsg4Title] = useState<string>('')
  const [msg4Detail, setMsg4Detail] = useState<string>('')
  const [msg4Name, setMsg4Name] = useState<string>('')
  const [msg4CorpName, setMsg4CorpName] = useState<string>('')
  const [msg4Tel, setMsg4Tel] = useState<string>('')

  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [dialogContents, setDialogContents] = useState<string>('')
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false)
  const [showNotifyDialog, setShowNotifyDialog] = useState<boolean>(false)

  const aboutString = String(about ?? '')

  const getAboutAsString = (): string => {
    if (about) {
      return ABOUT_ARRAY.find((e) => e.value === about)!.label
    }
    return ''
  }

  const changeEmail = (val: string) => {
    setEmail(val)
    setMsg4Email('')
  }

  const changeAboutString = (val: string) => {
    if (isInteger(val)) {
      setAbout(parseInt(val, 10))
    } else {
      setAbout(null)
    }
    setMsg4About('')
  }

  const changeTitle = (val: string) => {
    setTitle(val)
    setMsg4Title('')
  }

  const changeDetail = (val: string) => {
    setDetail(val)
    setMsg4Detail('')
  }

  const changeName = (val: string) => {
    setName(val)
    setMsg4Name('')
  }

  const changeCorpName = (val: string) => {
    setCorpName(val)
    setMsg4CorpName('')
  }

  const changeTel = (val: string) => {
    setTel(val)
    setMsg4Tel('')
  }

  const changeIsAgreePrivatePolicy = (val: boolean) => {
    setIsAgreePrivatePolicy(val)
    setIsEnableSend(val)
  }

  /**
   * エラーメッセージを表示する
   */
  const showErrorMessage = (): void => {
    setDialogTitle(CONTAIN_ERROR_TITLE)
    setDialogContents(COMMON_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  /**
   * システムエラーメッセージを表示する
   */
  const showFatalErrorMessage = (): void => {
    setDialogTitle(FATAL_ERROR_TITLE)
    setDialogContents(FATAL_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  const closeErrorDialog = () => {
    setShowErrorDialog(false)
  }

  /**
   *
   */
  const closeConfirmDialog = () => {
    setShowConfirmDialog(false)
  }

  const closeNotifyDialog = () => {
    setShowNotifyDialog(false)
  }

  const checkInquiry = (): boolean => {
    if (checkAll() === false) {
      showErrorMessage()
      return false
    }
    return true
  }

  /**
   * お問合せの実行
   */
  const doInquiry = async (): Promise<boolean> => {
    closeConfirmDialog()
    try {
      const params = createParameters()
      const data = await sendInquiry(params)
      if (data.status === RESPONSE_OK) {
        // showNotifyMessage()
        return true
      }
      // エラー発生時
      const title = 'エラー'
      const message = data.msgs![0].message ?? '予期せぬエラー'
      // エラーコード：508 -> 入力されたメールアドレスへメール送信できない場合のエラー
      if (data.code === 508) {
        setMsg4Email(message)
      }
      setDialogTitle(title)
      setDialogContents(message)
      setShowErrorDialog(true)
      return false
    } catch (e: any) {
      // SessionTimeout処理
      if (gStates.handleSessionExpired(e)) return false
      // 致命的エラー処理
      showFatalErrorMessage()
      return false
    }
  }

  const checkAll = (): boolean => {
    const resultAll: boolean[] = []
    resultAll.push(checkEmail())
    resultAll.push(checkAbout())
    resultAll.push(checkTitle())
    resultAll.push(checkDetail())
    resultAll.push(checkName())
    resultAll.push(checkCorpName())
    resultAll.push(checkTel())
    return resultAll.includes(false) === false
  }
  const checkEmail = (): boolean => {
    const item = email
    const label = 'メールアドレス'
    // 必須入力チェック
    if (hasNotValue(item)) {
      setMsg4Email(requiredMessage(label))
      return false
    }
    // 桁数チェック
    if (invalidMaxLength(item, 200)) {
      setMsg4Email(lengthLessThanMessage(label, 200, item.length))
      return false
    }

    // 書式チェック
    if (invalidMailAddress(item)) {
      setMsg4Email(emailRegexpMessage())
      return false
    }

    return true
  }
  const checkAbout = (): boolean => {
    const item = about
    const label = 'お問い合わせ項目'
    // 必須入力チェック
    if (hasNotValue(item)) {
      setMsg4About(requiredMessage(label))
      return false
    }
    return true
  }
  const checkTitle = (): boolean => {
    const item = title
    const label = 'お問い合わせの件名'
    // 必須入力チェック
    if (hasNotValue(item)) {
      setMsg4Title(requiredMessage(label))
      return false
    }
    // 桁数チェック
    if (invalidMaxLength(item, 100)) {
      setMsg4Title(lengthLessThanMessage(label, 100, item.length))
      return false
    }
    return true
  }
  const checkDetail = (): boolean => {
    const item = detail
    const label = 'お問い合わせの詳細'
    // 必須入力チェック
    if (hasNotValue(item)) {
      setMsg4Detail(requiredMessage(label))
      return false
    }
    // 桁数チェック
    if (invalidMaxLength(item, 2000)) {
      setMsg4Detail(lengthLessThanMessage(label, 2000, item.length))
      return false
    }

    return true
  }
  const checkName = (): boolean => {
    const item = name
    const label = 'お名前'
    // 必須入力チェック
    if (hasNotValue(item)) {
      setMsg4Name(requiredMessage(label))
      return false
    }
    // 桁数チェック
    if (invalidMaxLength(item, 50)) {
      setMsg4Name(lengthLessThanMessage(label, 50, item.length))
      return false
    }
    return true
  }
  const checkCorpName = (): boolean => {
    const item = corpName
    const label = '会社名'
    // 必須入力チェック
    if (hasNotValue(item)) {
      setMsg4CorpName(requiredMessage(label))
      return false
    }
    // 桁数チェック
    if (invalidMaxLength(item, 50)) {
      setMsg4CorpName(lengthLessThanMessage(label, 50, item.length))
      return false
    }
    return true
  }
  const checkTel = (): boolean => {
    const item = tel
    const label = '電話番号'
    // 必須入力チェック：お問い合わせ項目が「緊急連絡」の場合のみ
    if (hasValue(about) && about === 5) {
      if (hasNotValue(item)) {
        setMsg4Tel('緊急連絡の場合は、電話番号を入力してください')
        return false
      }
    }
    // フォーマットチェック
    if (invalidNumChars(item)) {
      setMsg4Tel(numCharsOnlyMessage(label))
      return false
    }

    // 桁数チェック
    if (invalidMaxLength(item, 11)) {
      setMsg4Tel(lengthLessThanMessage(label, 11))
      return false
    }
    return true
  }

  const clear = () => {
    setEmail('')
    setAbout(0)
    setTitle('')
    setDetail('')
    setName('')
    setCorpName('')
    setTel('')
    setIsAgreePrivatePolicy(false)
    setIsEnableSend(false)

    clearMsg()
  }

  const clearMsg = () => {
    setMsg4Email('')
    setMsg4About('')
    setMsg4Title('')
    setMsg4Detail('')
    setMsg4Name('')
    setMsg4CorpName('')
    setMsg4Tel('')
  }

  const createParameters = (): InquiryInfo => {
    const aboutValue = about!
    return {
      email,
      about: aboutValue,
      title,
      detail,
      name,
      corpName,
      tel
    }
  }

  // --【表示処理】------------------------------------------
  /**
   * 外部に公開するState群.
   */
  const globalStates: InquiryStateProps = {
    aboutList,

    email,
    aboutString,
    title,
    detail,
    name,
    corpName,
    tel,
    isAgreePrivatePolicy,
    isEnableSend,

    msg4Email,
    msg4About,
    msg4Title,
    msg4Detail,
    msg4Name,
    msg4CorpName,
    msg4Tel,

    getAboutAsString,
    changeEmail,
    changeAboutString,
    changeTitle,
    changeDetail,
    changeName,
    changeCorpName,
    changeTel,
    changeIsAgreePrivatePolicy,

    dialogTitle,
    dialogContents,
    showErrorDialog,
    showConfirmDialog,
    showNotifyDialog,

    closeErrorDialog,
    closeConfirmDialog,
    closeNotifyDialog,

    checkInquiry,
    doInquiry,
    clear,
    clearMsg
  }

  return (
    // レイアウトコンポーネントを処理コンポーネントでラップする
    <InquiryStateContext.Provider value={globalStates}>
      {/* children：レイアウト担当コンポーネント部 */}
      {children}
    </InquiryStateContext.Provider>
  )
}
