// メールアドレス正規表現
const REGEXP_MAIL_ADDRESS =
  /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
// パスワード正規表現
// const REGEXP_PASSWORD = /^(?=.*?[a-z])(?=.*?[A-Z]@_-)(?=.*?\d)[a-zA-Z\d.@_-]{8,20}$/
// const REGEXP_PASSWORD = /^(?=.*?[a-z])(?=.*?\d)[a-zA-Z\d.@_-]{6,20}$/
const REGEXP_PASSWORD = /^(?=.*?\d)[a-zA-Z\d.@_-]{6,20}$/
// パスワード使用可能正規表現
const REGEXP_USEFUL_PASSWORD = /^[a-zA-Z0-9.@_-]{6,20}$/
// ログインID正規表現
const REGEXP_LOGIN_ID = /^[a-zA-Z0-9.@＠&＆・•_-]{3,20}$/
// 電話番号正規表現
const REGEXP_PHONE_NUMBER = /\d{2,4}-\d{2,4}-\d{4}/
// 正の整数 正規表現
const REGEXP_INT = /^[0-9]{1,}$/
// 時 正規表現
const REGEXP_HOUR = /^([0-9]|[01][0-9]|2[0-3])$/
// 分 正規表現
const REGEXP_MINUTE = /^([0-9]|[0-5][0-9])$/

/**
 *
 * @param e
 * @returns
 */
export const isSessionExpired = (e: unknown): boolean => {
  return e instanceof Error && e.message === '999'
}

/**
 * 入力値があるかをチェックする.
 *
 * @param target
 * @returns
 */
export const hasValue = (target: any): boolean => {
  return hasNotValue(target) === false
}

/**
 * 入力値がないかをチェックする.
 * 文字列型の場合、空白（''）は未入力とみなす。
 * 数値型の場合、0は未入力とみなす。
 *
 * @param target
 * @returns
 */
export const hasNotValue = (target: any): boolean => {
  if (target === undefined) return true
  if (target === null) return true
  // 文字列型の場合
  if (typeof target === 'string' && target === '') return true
  // 数値型の場合
  if (typeof target === 'number' && target === 0) return true

  return false
}

/**
 * 入力文字の桁数をチェックする。
 * @param target
 * @param length
 * @returns
 */
export const invalidLength = (target: string, length: number): boolean => {
  // 未入力はOKとする
  if (hasNotValue(target)) return false
  // 桁数チェック
  return target.length !== length
}

/**
 * 入力文字の最大桁数をチェックする。
 * @param target
 */
export const invalidMaxLength = (target: string, maxLength: number): boolean => {
  // 未入力はOKとする
  if (hasNotValue(target)) return false
  // 桁数チェック
  return target.length > maxLength
}

/**
 * 桁数の範囲チェックをする。
 * @param target
 * @param min
 * @param max
 * @returns
 */
export const invalidCharsRange = (target: string, min: number, max: number): boolean => {
  // 未入力はOKとする
  if (hasNotValue(target)) return false
  //
  const length = target.length
  return length < min || max < length
}

/**
 * 入力値がメールアドレスとして妥当であるかをチェックする.
 * @param mailAddress
 * @returns
 */
export const invalidMailAddress = (mailAddress: string): boolean => {
  // 未入力はOKとする
  if (hasNotValue(mailAddress)) return false
  // 正規表現によるメールアドレスチェック
  return REGEXP_MAIL_ADDRESS.test(mailAddress) === false
}

/**
 * 入力値がパスワードとして妥当であるかをチェックする.
 * @param password
 * @returns
 */
export const invalidPassword = (password: string): boolean => {
  // 未入力はOKとする
  if (hasNotValue(password)) return false
  // 正規表現によるメールアドレスチェック
  return REGEXP_PASSWORD.test(password) === false
}

/**
 *
 * @param password
 * @returns
 */
export const usefulPassword = (password: string): boolean => {
  // 未入力はOKとする
  if (hasNotValue(password)) return false
  // 正規表現によるメールアドレスチェック
  return REGEXP_USEFUL_PASSWORD.test(password) === false
}

/**
 * 入力値がパスワードとして妥当であるかをチェックする.
 * @param password
 * @returns
 */
export const invalidLoginId = (loginId: string): boolean => {
  // 未入力はOKとする
  if (hasNotValue(loginId)) return false
  // 正規表現によるメールアドレスチェック
  return REGEXP_LOGIN_ID.test(loginId) === false
}

/**
 * 入力値が電話番号として妥当であるかをチェックする。
 * @param phoneNumber
 * @returns
 */
export const invalidPhoneNumber = (phoneNumber: string): boolean => {
  // 未入力はOKとする
  if (hasNotValue(phoneNumber)) return false
  // 正規表現によるメールアドレスチェック
  return REGEXP_PHONE_NUMBER.test(phoneNumber) === false
}

/**
 *
 * @param num
 */
export const invalidNumChars = (num: string): boolean => {
  // 未入力はOKとする
  if (hasNotValue(num)) return false
  // 正規表現による数字文字チェック
  return REGEXP_INT.test(num) === false
}

export const invalidHour = (hour: string): boolean => {
  // 未入力はOKとする
  if (hasNotValue(hour)) return false
  // 整数値チェック
  // 正規表現
  return REGEXP_HOUR.test(hour) === false
}

export const isInteger = (val: string): boolean => {
  // 未入力はOKとする
  if (hasNotValue(val)) return false
  //
  return REGEXP_INT.test(val)
}

export const invalidMinute = (minute: string): boolean => {
  // 未入力はOKとする
  if (hasNotValue(minute)) return false
  // 整数値チェック
  // 正規表現
  return REGEXP_MINUTE.test(minute) === false
}

export const splitTime = (time: string): Array<string> => {
  if (hasNotValue(time)) return ['', '']
  if (time.length !== 4) return ['00', '00']
  return [time.substring(0, 2), time.substring(2, 4)]
}
