import { createContext, useContext, useEffect, useState } from 'react'
import { getCoopTypeList } from 'api/common/masterCache'
import type { ProviderProps } from 'types/common/ProviderProps'
import type { ListElement } from 'types/common/ListElement'
import { findOne, registerContractor, removeContractor, updateContractor } from 'api/contractors'
import {
  hasNotValue,
  hasValue,
  invalidCharsRange,
  invalidLoginId,
  invalidMaxLength,
  invalidNumChars,
  invalidPassword,
  usefulPassword
} from 'utils/validators'
import {
  COMMON_ERROR_MESSAGE,
  CONTAIN_ERROR_TITLE,
  FATAL_ERROR_MESSAGE,
  FATAL_ERROR_TITLE,
  getConfirmMessage,
  getConfirmTitle,
  getNotifyMessage,
  getNotifyTitle,
  lengthLessThanMessage,
  lengthRangeMessage,
  loginIdRegexpMessage,
  numCharsOnlyMessage,
  passwordRegexpMessage,
  passwordUsefulMessage,
  requiredMessage,
  reverseOrderMessage
} from 'utils/messageUtil'
import { EXEC_TYPE } from 'types/common/ExecType'
import { EXEC_DELETE, EXEC_INSERT, EXEC_UPDATE, RESPONSE_OK } from 'utils/constants'
import type { ContractorInfo } from 'types/infos/ContractorInfo'
import { useLoginState } from 'components/common/provider/LoginStateProvider'
import { toWareki } from 'utils/dateUtil'
import { useGlobalState } from 'components/common/provider/GlobalStateProvider'

// ========================================================
/**
 *
 */
interface StateProps {
  coopTypes: ListElement[]

  userType: number
  loginId: string
  password: string
  passwordConfirm: string
  contractorName: string
  roomNo: string
  name: string
  // email: string
  // tel: string
  usageStartDate: Date | null
  usageEndDate: Date | null
  coopType: number
  isSuspended: boolean
  isSuspendedString: string
  usageStartDataString: string
  usageEndDataString: string
  coopTypeString: string

  msg4LoginId: string
  msg4Password: string
  msg4PasswordConfirm: string
  msg4ContractorName: string
  msg4RoomNo: string
  msg4Name: string
  // msg4Email: string
  // msg4Tel: string
  msg4StartDate: string
  msg4EndDate: string
  msg4CoopType: string
  msg4IsSuspended: string

  dialogTitle: string
  dialogContents: string
  showErrorDialog: boolean
  showConfirmDialog: boolean
  showNotifyDialog: boolean

  changeContractorId: (val: number) => void
  changeContractorName: (val: string) => void
  changeLoginId: (val: string) => void
  changeRoomNo: (val: string) => void
  changePassword: (val: string) => void
  changePasswordConfirm: (val: string) => void
  changeUsageStartDate: (val: Date) => void
  changeUsageEndDate: (val: Date) => void
  changeCoopType: (val: string) => void
  changeName: (val: string) => void
  // changeEmail: (val: string) => void
  // changeTel: (val: string) => void
  changeIsSuspended: (val: boolean) => void

  closeErrorDialog: () => void
  closeConfirmDialog: () => void
  closeNotifyDialog: () => void

  refreshById: (id: number) => void
  refresh: (type: EXEC_TYPE, info?: ContractorInfo) => void
  insert: () => void
  update: () => void
  remove: () => void
  clear: () => void
  doCallback: () => Promise<void>
}

/**
 *
 */
export const ContractorStateContext = createContext({} as StateProps)

// ========================================================
/**
 *
 * @returns
 */
export const useContractorState = () => useContext(ContractorStateContext)

// ========================================================
/**
 *
 * @param props
 * @returns
 */
export const ContractorStateProvider = (props: ProviderProps) => {
  //
  const { children } = props
  const gStates = useGlobalState()
  const lStates = useLoginState()

  // --【ステート】------------------------------------------
  // ドロップダウンリスト、ラジオボタンリスト
  const [coopTypes, setCoopTypes] = useState<ListElement[]>([])

  const [openMode, setOpenMode] = useState<EXEC_TYPE>(EXEC_INSERT)
  const [execType, setExecType] = useState<EXEC_TYPE | null>(null)
  const [originalInfo, setOriginalInfo] = useState<ContractorInfo | null>(null)

  // 画面項目用State
  const [contractorId, setContractorId] = useState<number | null>(null) // 更新・削除時のみ使用
  const [userType, setUserType] = useState<number>(0)
  const [loginId, setLoginId] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('')
  const [contractorName, setContractorName] = useState<string>('')
  const [roomNo, setRoomNo] = useState<string>('')
  const [name, setName] = useState<string>('')
  // const [email, setEmail] = useState<string>('')
  // const [tel, setTel] = useState<string>('')
  const [usageStartDate, setUsageStartDate] = useState<Date | null>(null)
  const [usageEndDate, setUsageEndDate] = useState<Date | null>(null)
  const [coopType, setCoopType] = useState<number>(0)
  const [isSuspended, setIsSuspended] = useState<boolean>(false)

  // メッセージ表示用State
  const [msg4ContractorName, setMsg4ContractorName] = useState('')
  const [msg4LoginId, setMsg4LoginId] = useState('')
  const [msg4Password, setMsg4Password] = useState('')
  const [msg4PasswordConfirm, setMsg4PasswordConfirm] = useState('')
  const [msg4RoomNo, setMsg4RoomNo] = useState('')
  const [msg4Name, setMsg4Name] = useState('')
  // const [msg4Email, setMsg4Email] = useState('')
  // const [msg4Tel, setMsg4Tel] = useState('')
  const [msg4StartDate, setMsg4StartDate] = useState('')
  const [msg4EndDate, setMsg4EndDate] = useState('')
  const [msg4CoopType, setMsg4CoopType] = useState('')
  const [msg4IsSuspended, setMsg4IsSuspended] = useState('')

  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [dialogContents, setDialogContents] = useState<string>('')
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false)
  const [showNotifyDialog, setShowNotifyDialog] = useState<boolean>(false)

  // --【導出プロパティ】------------------------------------------
  // 本来のデータ型 -> string型へのコンバート用
  const isSuspendedString = !isSuspended ? '使用停止中' : '通常利用'
  const usageStartDataString = usageStartDate ? toWareki(usageStartDate) : ''
  const usageEndDataString = usageEndDate ? toWareki(usageEndDate) : '（未設定）'
  const coopTypeString = coopType === 1 ? '法人' : coopType === 2 ? '個人事業主など' : ''
  const isAdmin = contractorId === 1

  // --【初期表示処理】------------------------------------------

  useEffect(() => {
    // ラジオボタンリストを取得するfunction
    const func = async () => {
      const list = await getCoopTypeList()
      setCoopTypes(list)
    }
    func()

    if (!contractorId) {
      clearValues()
      return
    }
  }, [])

  /**
   * ブラウザリロード対策：ログインユーザー契約社IDを監視する。
   * ログインユーザー契約社IDが初期値（-1）の場合は処理をスキップ。
   * それ以外の場合は、契約社情報をリフレッシュ処理する。
   */
  useEffect(() => {
    if (lStates.loginContractorId === -1) return
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lStates.loginContractorId])

  // --【change イベントハンドラ】------------------------------------------
  const changeContractorId = (val: number) => {
    setContractorId(() => val)
  }

  /**
   *
   * @param val
   */
  const changeContractorName = (val: string) => {
    setContractorName(() => val)
    if (val !== '') setMsg4ContractorName(() => '')
  }

  /**
   *
   * @param val
   */
  const changeLoginId = (val: string) => {
    setLoginId(() => val)
    if (val !== '') setMsg4LoginId(() => '')
  }

  /**
   *
   * @param val
   */
  const changePassword = (val: string) => {
    setPassword(() => val)
    if (password !== '') setMsg4Password(() => '')
  }

  /**
   *
   * @param val
   */
  const changePasswordConfirm = (val: string) => {
    setPasswordConfirm(() => val)
    if (passwordConfirm !== '') setMsg4PasswordConfirm(() => '')
  }

  /**
   *
   * @param val
   */
  const changeRoomNo = (val: string) => {
    setRoomNo(() => val)
    if (roomNo !== '') setMsg4RoomNo(() => '')
  }

  /**
   *
   * @param val
   */
  const changeUsageStartDate = (val: Date | null) => {
    setUsageStartDate(val)
    setMsg4StartDate('')
  }

  /**
   *
   * @param val
   */
  const changeUsageEndDate = (val: Date | null) => {
    setUsageEndDate(val)
    setMsg4EndDate('')
  }

  /**
   *
   * @param val
   */
  const changeCoopType = (val: string) => {
    if (val) setCoopType(() => parseInt(val, 10))
    if (val !== '') setMsg4CoopType(() => '')
  }

  /**
   *
   * @param val
   */
  const changeName = (val: string) => {
    setName(() => val)
    if (name !== '') setMsg4Name(() => '')
  }

  /**
   *
   * @param val
   */
  // const changeEmail = (val: string) => {
  //   setEmail(() => val)
  //   if (email !== '') setMsg4Email(() => '')
  // }

  /**
   *
   * @param val
   */
  // const changeTel = (val: string) => {
  //   setTel(() => val)
  //   if (tel !== '') setMsg4Tel(() => '')
  // }

  /**
   *
   * @param val
   */
  const changeIsSuspended = (val: boolean) => {
    setIsSuspended(() => val)
    setMsg4IsSuspended('')
  }

  const closeErrorDialog = () => {
    setShowErrorDialog(false)
  }

  const closeConfirmDialog = () => {
    setShowConfirmDialog(false)
  }

  const closeNotifyDialog = () => {
    setShowNotifyDialog(false)
  }

  // --【click イベントハンドラ】------------------------------------------

  /**
   * 「新規登録」イベントリスナ
   */
  const insert = () => {
    // エラーチェック
    if (checkAll(EXEC_INSERT) === false) {
      showErrorMessage()
      return
    }
    // 実行確認
    setExecType(EXEC_INSERT)
    showConfirmMessage(EXEC_INSERT)
  }
  /**
   * 「新規登録」コールバックメソッド
   * @returns
   */
  const doInsert = async () => {
    // 確認ダイアログを閉じる
    closeConfirmDialog()

    try {
      const params = createRequestParams()
      const data = await registerContractor(params)
      // 正常終了時
      if (data.status === RESPONSE_OK) {
        showNotifyMessage(EXEC_INSERT)
        return
      }

      // エラー発生時
      // SessionTimeout処理
      if (gStates.handleSessionExpiredCode(data.code)) return

      const title = 'エラー'
      const message = data.msgs![0].message ?? '予期せぬエラー'
      if (data.code === 502) {
        setMsg4LoginId(message)
      } else if (data.code === 503) {
        setMsg4RoomNo(message)
      } else if (data.code === 507) {
        setMsg4ContractorName(message)
      }
      setDialogTitle(title)
      setDialogContents(message)
      setShowErrorDialog(true)
    } catch (e) {
      // システムエラーメッセージの表示
      showFatalErrorMessage()
    }
  }

  /**
   * 「更新」イベントリスナ
   */
  const update = () => {
    setExecType(EXEC_UPDATE)
    if (checkAll(EXEC_UPDATE) === false) {
      showErrorMessage()
      return
    }
    showConfirmMessage(EXEC_UPDATE)
  }
  /**
   * 「更新」コールバックメソッド
   * @returns
   */
  const doUpdate = async () => {
    // 確認ダイアログを閉じる
    closeConfirmDialog()

    try {
      const params = createRequestParams()
      const data = await updateContractor(params)

      // 正常終了時
      if (data.status === RESPONSE_OK) {
        showNotifyMessage(EXEC_INSERT)
        return
      }

      // エラー発生時
      const title = data.msgs![0].message ? 'エラー' : FATAL_ERROR_TITLE
      const message = data.msgs![0].message ?? FATAL_ERROR_MESSAGE
      // SessionTimeout処理
      if (gStates.handleSessionExpiredCode(data.code)) return

      if (data.code === 502) {
        setMsg4LoginId(message)
      } else if (data.code === 503) {
        setMsg4RoomNo(message)
      } else if (data.code === 507) {
        setMsg4ContractorName(message)
      }
      setDialogTitle(title)
      setDialogContents(message)
      setShowErrorDialog(true)
    } catch (e) {
      // システムエラーメッセージの表示
      showFatalErrorMessage()
    }
  }

  /**
   * 「削除」イベントリスナ
   */
  const remove = () => {
    setExecType(EXEC_DELETE)
    showConfirmMessage(EXEC_DELETE)
  }
  /**
   * 「削除」コールバックメソッド
   * @returns
   */
  const doRemove = async () => {
    // 確認ダイアログを閉じる
    closeConfirmDialog()

    try {
      await removeContractor(contractorId!)
      // 通知ダイアログ表示
      showNotifyMessage(EXEC_DELETE)
    } catch (e) {
      // SessionTimeout処理
      if (gStates.handleSessionExpired(e)) return
      // システムエラーメッセージの表示
      showFatalErrorMessage()
    }
  }

  /**
   * 「クリア」イベントリスナ
   */
  const clear = () => {
    clearMsgs()
    if (openMode === EXEC_INSERT) {
      clearValues()
    } else {
      restoreValues(originalInfo!)
    }
  }

  const doCallback = async () => {
    if (!execType) throw new Error('実行種別が指定されていません。')

    if (execType === EXEC_INSERT) {
      await doInsert()
    }
    if (execType === EXEC_UPDATE) {
      await doUpdate()
    }
    if (execType === EXEC_DELETE) {
      await doRemove()
    }
  }

  /**
   * エラーメッセージを表示する
   */
  const showErrorMessage = (): void => {
    setDialogTitle(CONTAIN_ERROR_TITLE)
    setDialogContents(COMMON_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  /**
   * 確認メッセージを表示する
   * @param type
   */
  const showConfirmMessage = (type: EXEC_TYPE): void => {
    setDialogTitle(getConfirmTitle(type))
    setDialogContents(getConfirmMessage(type, '契約社情報'))
    setShowConfirmDialog(true)
  }

  /**
   * 通知メッセージを表示する
   * @param type
   */
  const showNotifyMessage = (type: EXEC_TYPE): void => {
    setDialogTitle(getNotifyTitle(type))
    setDialogContents(getNotifyMessage(type, '契約社情報'))
    setShowNotifyDialog(true)
  }

  /**
   * システムエラーメッセージを表示する
   */
  const showFatalErrorMessage = (): void => {
    setDialogTitle(FATAL_ERROR_TITLE)
    setDialogContents(FATAL_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  /**
   *
   * @param id
   */
  const refreshById = (id: number) => {
    //
    findOne(id)
      .then((info: ContractorInfo) => {
        restoreValues(info)
      })
      .catch((e: unknown) => {
        // SessionTimeout処理
        if (gStates.handleSessionExpired(e)) return
        // システムエラーメッセージの表示
        showFatalErrorMessage()
      })
  }

  /**
   *
   * @param type
   * @param info
   * @returns
   */
  const refresh = (type: EXEC_TYPE, info?: ContractorInfo) => {
    setOpenMode(type)
    setOriginalInfo(info ?? null)

    if (!info) {
      clearValues()
      return
    }
    restoreValues(info!)
  }

  // -- PRIVATE 処理用 ------------------------------------------

  // --【共通処理】------------------------------------------
  /**
   *
   * @param info
   */
  const restoreValues = (info: ContractorInfo) => {
    setContractorId(info.contractorId!)
    setUserType(info.userType)
    setLoginId(info.loginId)
    setContractorName(info.contractorName)
    setPassword('')
    setPasswordConfirm('')
    setRoomNo(info.roomNo)
    setUsageStartDate(new Date(info.usageStartDate!))
    const endDate = info.usageEndDate ? new Date(info.usageEndDate) : null
    setUsageEndDate(endDate)
    setCoopType(info.coopType)
    // setEmail(info.email)
    setName(info.name)
    // setTel(info.tel)
    setIsSuspended(info.isSuspended)
  }
  /**
   * 入力値のクリア
   */
  const clearValues = () => {
    setContractorId(null)
    setContractorName('')
    setUserType(0)
    setLoginId('')
    setPassword('')
    setPasswordConfirm('')
    setRoomNo('')
    setUsageStartDate(null)
    setUsageEndDate(null)
    setCoopType(0)
    // setEmail('')
    setName('')
    // setTel('')
    setIsSuspended(false)
  }
  /**
   * エラーメッセージのクリア
   */
  const clearMsgs = () => {
    setMsg4LoginId(() => '')
    setMsg4Password(() => '')
    setMsg4PasswordConfirm(() => '')
    setMsg4ContractorName(() => '')
    setMsg4RoomNo(() => '')
    setMsg4CoopType(() => '')
    setMsg4StartDate(() => '')
    setMsg4EndDate(() => '')
    setMsg4Name(() => '')
    // setMsg4Email(() => '')
    // setMsg4Tel(() => '')
    setMsg4IsSuspended(() => '')
  }
  /**
   *
   * @returns ContractorParams型：戻り値型を指定して型チェックを実施
   */
  const createRequestParams = (): ContractorInfo => {
    return {
      contractorId,
      userType,
      loginId,
      contractorName,
      roomNo,
      password,
      name,
      // email,
      // tel,
      usageStartDate,
      usageEndDate,
      coopType,
      isSuspended
    }
  }

  // --【バリデータ】------------------------------------------

  /**
   *
   * @returns
   */
  const checkAll = (type: EXEC_TYPE) => {
    const allResult = [] as boolean[]
    allResult.push(checkContractName())
    allResult.push(checkLoginId())
    allResult.push(checkPassword(type))
    allResult.push(checkPasswordConfirm(type))
    allResult.push(checkPasswordMatch())
    if (isAdmin === false) {
      allResult.push(checkRoomNo())
    }
    // allResult.push(checkEmail())
    allResult.push(checkName())
    // allResult.push(checkTel())
    allResult.push(checkUsageStartDate())
    allResult.push(checkUsageEndDate())
    allResult.push(checkDateRelation())
    allResult.push(checkCoopType())
    return allResult.includes(false) === false
  }

  /**
   *
   * @returns
   */
  const checkContractName = () => {
    // 必須入力
    if (hasNotValue(contractorName)) {
      setMsg4ContractorName(() => requiredMessage('契約社名'))
      return false
    }
    // 桁数チェック
    if (invalidMaxLength(contractorName, 100)) {
      setMsg4ContractorName(() => lengthLessThanMessage('契約社名', 100))
      return false
    }

    return true
  }

  const checkLoginId = () => {
    // 必須入力
    if (hasNotValue(loginId)) {
      setMsg4LoginId(() => requiredMessage('ログインID'))
      return false
    }
    // 桁数チェック
    if (invalidCharsRange(loginId, 3, 20)) {
      setMsg4LoginId(() => lengthRangeMessage('ログインID', 3, 20))
      return false
    }
    // 文字種チェック
    if (invalidLoginId(loginId)) {
      setMsg4LoginId(() => loginIdRegexpMessage())
      return false
    }
    return true
  }

  /**
   *
   * @returns
   */
  const checkPassword = (type: EXEC_TYPE) => {
    // 管理者、かつ、更新時の場合
    if (lStates.isAdmin() && type === EXEC_UPDATE) {
      // パスワード未入力ならチェックなし
      if (hasNotValue(password) && hasNotValue(passwordConfirm)) {
        return true
      }
    }

    // 必須入力
    if (hasNotValue(password)) {
      setMsg4Password(() => requiredMessage('パスワード'))
      return false
    }
    // 桁数範囲
    if (invalidCharsRange(password, 6, 20)) {
      setMsg4Password(() => lengthRangeMessage('パスワード', 6, 20))
      return false
    }
    // 使用可能文字チェック
    if (usefulPassword(password)) {
      setMsg4Password(() => passwordUsefulMessage())
      return false
    }
    // 必須使用文字チェック（大文字・小文字・数字）
    if (invalidPassword(password)) {
      setMsg4Password(() => passwordRegexpMessage())
      return false
    }
    return true
  }

  /**
   *
   * @returns
   */
  const checkPasswordConfirm = (type: EXEC_TYPE) => {
    // 管理者、かつ、更新時の場合
    if (lStates.isAdmin() && type === EXEC_UPDATE) {
      // パスワード未入力ならチェックなし
      if (hasNotValue(password) && hasNotValue(passwordConfirm)) {
        return true
      }
    }

    // 必須入力
    if (hasNotValue(passwordConfirm)) {
      setMsg4PasswordConfirm(() => requiredMessage('パスワード（確認）'))
      return false
    }
    // 桁数範囲
    if (invalidCharsRange(passwordConfirm, 6, 20)) {
      setMsg4PasswordConfirm(() => lengthRangeMessage('パスワード（確認）', 6, 20))
      return false
    }
    // 使用可能文字チェック
    if (usefulPassword(password)) {
      setMsg4PasswordConfirm(() => passwordUsefulMessage('パスワード（確認）'))
      return false
    }
    // 必須使用文字チェック（大文字・小文字・数字）
    if (invalidPassword(password)) {
      setMsg4PasswordConfirm(() => passwordRegexpMessage('パスワード（確認）'))
      return false
    }
    return true
  }

  /**
   *
   * @returns
   */
  const checkPasswordMatch = () => {
    // 値が未入力の場合はチェックしない
    if (hasNotValue(password) || hasNotValue(passwordConfirm)) return true
    // 既にエラーが発生している場合は、チェックしない
    if (hasValue(msg4Password) || hasValue(msg4PasswordConfirm)) return true
    //
    if (password !== passwordConfirm) {
      const msg = 'パスワードとパスワード（確認）が一致しません。'
      setMsg4Password(() => msg)
      setMsg4PasswordConfirm(() => msg)
      return false
    }
    return true
  }

  /**
   *
   * @returns
   */
  const checkRoomNo = () => {
    // 必須入力
    if (hasNotValue(roomNo)) {
      setMsg4RoomNo(() => requiredMessage('ルームNo'))
      return false
    }
    // 桁数チェック
    if (invalidMaxLength(roomNo, 4)) {
      setMsg4RoomNo(() => lengthLessThanMessage('ルームNo', 4))
      return false
    }

    // 文字種チェック
    if (invalidNumChars(roomNo)) {
      setMsg4RoomNo(() => numCharsOnlyMessage('ルームNo'))
      return false
    }

    return true
  }

  /**
   *
   * @returns
   */
  const checkUsageStartDate = () => {
    // 必須入力
    if (hasNotValue(usageStartDate)) {
      setMsg4StartDate(() => requiredMessage('契約開始日'))
      return false
    }
    return true
  }

  /**
   *
   * @returns
   */
  const checkUsageEndDate = () => {
    return true
  }

  /**
   *
   * @returns
   */
  const checkDateRelation = (): boolean => {
    // 値が未入力の場合はチェックしない
    if (hasNotValue(usageStartDate) || hasNotValue(usageEndDate)) return true
    // 既にエラーが発生している場合は、チェックしない
    if (hasValue(msg4StartDate) || hasValue(msg4EndDate)) return true

    if (usageStartDate! > usageEndDate!) {
      setMsg4EndDate(() => reverseOrderMessage('契約開始日', '契約終了日'))
      return false
    }
    return true
  }

  /**
   *
   * @returns
   */
  const checkCoopType = () => {
    // 必須入力
    if (hasNotValue(coopType)) {
      setMsg4CoopType(() => requiredMessage('企業種別'))
      return false
    }
    return true
  }

  /**
   *
   * @returns
   */
  const checkName = () => {
    // 必須入力
    // if (hasNotValue(name)) {
    //   setMsg4Name(() => requiredMessage('担当者名'))
    //   return false
    // }
    // 桁数チェック
    if (invalidMaxLength(name, 100)) {
      setMsg4Name(() => lengthLessThanMessage('担当者名', 50))
      return false
    }

    return true
  }

  /**
   *
   * @returns
   */
  // const checkEmail = () => {
  //   // 必須入力
  //   if (hasNotValue(email)) {
  //     setMsg4Email(() => requiredMessage('担当メールアドレス'))
  //     return false
  //   }
  //   // メールアドレスフォーマット
  //   if (invalidMailAddress(email)) {
  //     setMsg4Email(() => emailRegexpMessage())
  //     return false
  //   }

  //   return true
  // }

  /**
   *
   * @returns
   */
  // const checkTel = () => {
  //   // 必須入力
  //   if (hasNotValue(tel)) {
  //     setMsg4Tel(() => requiredMessage('担当電話番号'))
  //     return false
  //   }
  //   // 電話番号フォーマット
  //   if (invalidPhoneNumber(tel)) {
  //     setMsg4Tel(() => phoneNumberRegexpMessage())
  //     return false
  //   }

  //   return true
  // }

  // --【表示処理】------------------------------------------

  /**
   *
   */
  const globalStates = {
    coopTypes,

    userType,
    loginId,
    password,
    passwordConfirm,
    contractorName,
    roomNo,
    name,
    // email,
    // tel,
    usageStartDate,
    usageEndDate,
    coopType,
    isSuspended,
    isSuspendedString,
    usageStartDataString,
    usageEndDataString,
    coopTypeString,

    msg4LoginId,
    msg4Password,
    msg4PasswordConfirm,
    msg4ContractorName,
    msg4RoomNo,
    msg4Name,
    // msg4Email,
    // msg4Tel,
    msg4StartDate,
    msg4EndDate,
    msg4CoopType,
    msg4IsSuspended,

    dialogTitle,
    dialogContents,
    showErrorDialog,
    showConfirmDialog,
    showNotifyDialog,

    changeContractorId,
    changeContractorName,
    changeLoginId,
    changePassword,
    changePasswordConfirm,
    changeRoomNo,
    changeUsageStartDate,
    changeUsageEndDate,
    changeCoopType,
    changeName,
    // changeEmail,
    // changeTel,
    changeIsSuspended,

    closeErrorDialog,
    closeConfirmDialog,
    closeNotifyDialog,

    refreshById,
    refresh,
    insert,
    update,
    remove,
    clear,
    doCallback
  } as StateProps

  return (
    <>
      <ContractorStateContext.Provider value={globalStates}>
        {children}
      </ContractorStateContext.Provider>
    </>
  )
}
