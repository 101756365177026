import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import InquiryForm from 'components/inquiry/InquiryForm'
import { InquiryStateProvider } from 'components/inquiry/InquiryStateProvider'
import { TITLE_INQUIRY } from 'utils/constants'
import InquiryConfirmForm from 'components/inquiry/InquiryConfirmForm'
import InquiryNotifyForm from 'components/inquiry/InquiryNotifyForm'

/**
 */
const Inquiry = () => {
  const [mode, setMode] = useState<number>(0)
  const changeMode = (mode: number) => {
    setMode(mode)
  }

  return (
    <InquiryStateProvider>
      <Helmet>
        <title>{TITLE_INQUIRY}</title>
      </Helmet>
      <InquiryForm isActive={mode === 0} changeMode={changeMode} />
      <InquiryConfirmForm isActive={mode === 1} changeMode={changeMode} />
      <InquiryNotifyForm isActive={mode === 2} changeMode={changeMode} />
    </InquiryStateProvider>
  )
}

export default Inquiry
