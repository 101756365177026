import { useNavigate } from 'react-router-dom'
import fStyles from 'styles/utils/form.module.scss'
import { useInquiryState } from './InquiryStateProvider'

/**
 *
 */
const InquiryNotifyForm = (props: { isActive: boolean; changeMode: (mode: number) => void }) => {
  const states = useInquiryState()
  const navigate = useNavigate()

  const returnToTop = () => {
    states.clear()
    navigate('/')
  }
  const returnToInquiry = () => {
    states.clear()
    props.changeMode(0)
  }

  return (
    <>
      {!props.isActive && <></>}
      {props.isActive && (
        <>
          <section className={fStyles.detailForm}>
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <span>お問い合わせ頂き誠にありがとうございます。</span>
              <br />
              <span>
                緊急を要するものは可能な限り速やかに、それ以外は2営業日以内に、弊社担当より折り返しご連絡させて頂きます。
              </span>
              <br />
              <br />
              <span>入力頂いたメールアドレスに確認メールを送信致しました。</span>
              <br />
              <span>
                確認メールが届かない場合はメールアドレス入力の間違いの可能性がございます。
              </span>
              <br />
              <span>その場合は、正しいメールアドレスで再度お問い合わせください</span>
            </div>
            <div className={fStyles.footerButtons}>
              <button className={fStyles.button} onClick={returnToTop}>
                トップへ
              </button>
              <button className={fStyles.button} onClick={returnToInquiry}>
                お問い合わせへ
              </button>
            </div>
          </section>
        </>
      )}
    </>
  )
}

export default InquiryNotifyForm
