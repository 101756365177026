import fStyles from 'styles/utils/form.module.scss'
import { useInquiryState } from './InquiryStateProvider'
import Label from 'components/common/atoms/Label'
import ErrorDialog from 'components/common/dialogs/messages/ErrorDialog'
import OutputLabel from 'components/common/atoms/OutputLabel'

/**
 *
 */
const InquiryConfirmForm = (props: { isActive: boolean; changeMode: (mode: number) => void }) => {
  const states = useInquiryState()

  const returnToInput = () => {
    states.clearMsg()
    props.changeMode(0)
  }

  const closeErrorDialog = () => {
    states.closeErrorDialog()
  }

  const inquiry = async () => {
    // メール送信機能
    if (await states.doInquiry()) {
      props.changeMode(2)
    }
  }

  return (
    <>
      {!props.isActive && <></>}
      {props.isActive && (
        <>
          <ErrorDialog
            title={states.dialogTitle}
            contents={states.dialogContents}
            show={states.showErrorDialog}
            onOk={closeErrorDialog}
          />

          <section className={fStyles.detailForm}>
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '20px',
                textAlign: 'center',
                color: 'red'
              }}
            >
              まだ、お問い合わせは送信されていません。
            </div>
            <div>
              <span>以下の内容でお問い合わせを送信します。</span>
              <br />
              <span>間違いがない場合は「送信」をクリックしてください。</span>
              <br />
              <span>間違いがある場合は「戻る」をクリックして内容を修正してください。</span>
            </div>
            <div className={fStyles.topSmallMargin} />
            <div className={fStyles.row}>
              <span className={fStyles.label}>
                <Label title="メールアドレス" />
              </span>
              <span className={fStyles.input}>
                <OutputLabel width={800} value={states.email} />
              </span>
            </div>
            <div className={fStyles.row}>
              <span className={fStyles.label}></span>
              <span className={`${fStyles.input} ${fStyles.separator}`}></span>
            </div>
            <div className={fStyles.row}>
              <span className={fStyles.label}>
                <Label title="お問い合わせ項目" />
              </span>
              <span className={fStyles.input}>
                <OutputLabel width={800} value={states.getAboutAsString()} />
              </span>
            </div>
            <div className={fStyles.row}>
              <span className={fStyles.label}></span>
              <span className={`${fStyles.input} ${fStyles.separator}`}></span>
            </div>
            <div className={fStyles.row}>
              <span className={fStyles.label}>
                <Label title="お問い合わせ件名" />
              </span>
              <span className={fStyles.input}>
                <OutputLabel width={800} value={states.title} />
              </span>
            </div>
            <div className={fStyles.row}>
              <span className={fStyles.label}></span>
              <span className={`${fStyles.input} ${fStyles.separator}`}></span>
            </div>
            <div className={fStyles.row}>
              <span className={fStyles.label}>
                <Label title="お問い合わせ詳細" />
              </span>
              <span className={fStyles.input}>
                <OutputLabel width={800} value={states.detail} />
              </span>
            </div>
            <div className={fStyles.row}>
              <span className={fStyles.label}></span>
              <span className={`${fStyles.input} ${fStyles.separator}`}></span>
            </div>
            <div className={fStyles.row}>
              <span className={fStyles.label}>
                <Label title="お名前" />
              </span>
              <span className={fStyles.input}>
                <OutputLabel width={800} value={states.name} />
              </span>
            </div>
            <div className={fStyles.row}>
              <span className={fStyles.label}></span>
              <span className={`${fStyles.input} ${fStyles.separator}`}></span>
            </div>
            <div className={fStyles.row}>
              <span className={fStyles.label}>
                <Label title="会社名" />
              </span>
              <span className={fStyles.input}>
                <OutputLabel width={800} value={states.corpName} />
              </span>
            </div>
            <div className={fStyles.row}>
              <span className={fStyles.label}></span>
              <span className={`${fStyles.input} ${fStyles.separator}`}></span>
            </div>
            <div className={fStyles.row}>
              <span className={fStyles.label}>
                <Label title="電話番号" />
              </span>
              <span className={fStyles.input}>
                <OutputLabel width={800} value={states.tel} />
              </span>
            </div>
            <div className={fStyles.footerButtons}>
              <button className={fStyles.button} onClick={inquiry}>
                送信
              </button>
              <button className={`${fStyles.button} ${fStyles.clear}`} onClick={returnToInput}>
                戻る
              </button>
            </div>
          </section>
        </>
      )}
    </>
  )
}

export default InquiryConfirmForm
