import { EXEC_TYPE } from 'types/common/ExecType'
import { EXEC_CLEAR, EXEC_DELETE, EXEC_INSERT, EXEC_UPDATE } from './constants'

/**
 *
 * @param name
 * @returns
 */
export const requiredMessage = (name: string): string => {
  return `${name}を入力してください`
}

/**
 *
 * @param name
 * @param length
 * @returns
 */
export const lengthLessThanMessage = (
  name: string,
  length: number,
  current: number | null = null
): string => {
  if (current) {
    return `${name}は${length}文字以内で入力してください：現在 ${current}文字`
  } else {
    return `${name}は${length}文字以内で入力してください`
  }
}

/**
 *
 * @param name
 * @param min
 * @param max
 * @returns
 */
export const lengthRangeMessage = (name: string, min: number, max: number): string => {
  return `${name}は${min}文字以上${max}文字以内で入力してください`
}

/**
 *
 * @param name
 * @param min
 * @param max
 * @returns
 */
export const numValueRangeMessage = (name: string, min: number, max: number): string => {
  return `${name}は${min}から${max}の範囲の数値で入力してください`
}

/**
 *
 * @param name
 * @param length
 * @returns
 */
export const valueLessThanMessage = (name: string, length: number): string => {
  return `${name}は${length}以下の値で入力してください`
}

/**
 *
 * @param name
 * @param val
 * @returns
 */
export const numCharsOnlyMessage = (name: string) => {
  return `${name}は数字だけで入力してください`
}

/**
 *
 * @param name
 * @returns
 */
export const emailRegexpMessage = (email: string = 'メールアドレス'): string => {
  return `正しい${email}ではありません`
}

/**
 *
 * @param phoneNumber
 * @returns
 */
export const phoneNumberRegexpMessage = (phoneNumber: string = '電話番号'): string => {
  return `正しい${phoneNumber}ではありません`
}

/**
 *
 * @param name
 * @returns
 */
export const passwordUsefulMessage = (name: string = 'パスワード'): string => {
  return `${name}に使用可能な文字は、半角英大小文字, 数字, '.' と '-' と '_' のみです`
}

/**
 *
 * @param name
 * @returns
 */
export const passwordRegexpMessage = (name: string = 'パスワード'): string => {
  return `${name}は、少なくとも英小文字・数字を１つ以上使用してください`
}

/**
 *
 * @param name
 * @returns
 */
export const loginIdRegexpMessage = (name: string = 'ログインID'): string => {
  return `${name}は、半角英数文字と . _ - @(半) ＠(全) &(半) ＆(全) •(半) ・(全)のみが使用可能です`
}

/**
 *
 * @param name
 * @param type
 * @returns
 */
export const invalidDataTypeMessage = (name: string, type: string): string => {
  return `${name}は${type}で入力して下さい。`
}

/**
 *
 * @param name1
 * @param name2
 * @returns
 */
export const reverseOrderMessage = (name1: string, name2: string): string => {
  return `${name1}と${name2}の前後が逆に指定されています。`
}

/**
 *
 * @param name1
 * @param name2
 * @returns
 */
export const equalOrReserveOrderMessage = (name1: string, name2: string): string => {
  return `${name1}と${name2}が同一か、または、前後が逆に指定されています。`
}

// ===== ダイアログメッセージ系

// タイトル
export const CONTAIN_ERROR_TITLE: string = 'エラーがあります'
export const FATAL_ERROR_TITLE: string = 'システムエラー'

export const CONFIRM_INSERT_TITLE: string = '新規登録します'
export const CONFIRM_UPDATE_TITLE: string = '更新します'
export const CONFIRM_DELETE_TITLE: string = '削除します'
export const CONFIRM_SEND_TITLE: string = '送信します'
export const CONFIRM_CLEAR_TITLE: string = 'クリアします'

export const NOTIFY_INSERT_TITLE: string = '新規登録完了'
export const NOTIFY_UPDATE_TITLE: string = '更新完了'
export const NOTIFY_DELETE_TITLE: string = '削除完了'
export const NOTIFY_SEND_TITLE: string = '送信完了'

// ダイアログメッセージ
export const COMMON_ERROR_MESSAGE: string =
  '入力内容に誤りがあります。各項目への入力値をご確認ください。'
export const FATAL_ERROR_MESSAGE: string = 'システムでエラーが発生しました。管理者へご連絡下さい。'

export const getConfirmTitle = (type: EXEC_TYPE): string => {
  if (type === EXEC_INSERT) return CONFIRM_INSERT_TITLE
  if (type === EXEC_UPDATE) return CONFIRM_UPDATE_TITLE
  if (type === EXEC_DELETE) return CONFIRM_DELETE_TITLE
  if (type === EXEC_CLEAR) return CONFIRM_CLEAR_TITLE
  throw new Error('INVALID EXEC TYPE.')
}

export const getNotifyTitle = (type: EXEC_TYPE): string => {
  if (type === EXEC_INSERT) return NOTIFY_INSERT_TITLE
  if (type === EXEC_UPDATE) return NOTIFY_UPDATE_TITLE
  if (type === EXEC_DELETE) return NOTIFY_DELETE_TITLE
  throw new Error('INVALID EXEC TYPE.')
}
export const getConfirmMessage = (type: EXEC_TYPE, target: string) => {
  if (type === EXEC_INSERT) return getInsertConfirmMessage(target)
  if (type === EXEC_UPDATE) return getUpdateConfirmMessage(target)
  if (type === EXEC_DELETE) return getDeleteConfirmMessage(target)
  if (type === EXEC_CLEAR) return CLEAR_CONFIRM_MESSAGE
  throw new Error('INVALID EXEC TYPE.')
}

export const getNotifyMessage = (type: EXEC_TYPE, target: string) => {
  if (type === EXEC_INSERT) return getInsertCompleteMsg(target)
  if (type === EXEC_UPDATE) return getUpdateCompleteMsg(target)
  if (type === EXEC_DELETE) return getDeleteCompleteMsg(target)
  throw new Error('INVALID EXEC TYPE.')
}

export const getInsertConfirmMessage = (target: string): string => {
  return `${target}を新規登録します。よろしいですか？`
}

export const getUpdateConfirmMessage = (target: string): string => {
  return `${target}を更新します。よろしいですか？`
}

export const getDeleteConfirmMessage = (target: string): string => {
  return `${target}を削除します。元に戻すことはできません。よろしいですか？`
}

export const getSendConfirmMessage = (target: string): string => {
  return `${target}を送信します。よろしいですか？`
}

export const CLEAR_CONFIRM_MESSAGE = '入力を元に戻します。よろしいですか？'

export const getInsertCompleteMsg = (target: string): string => {
  return `${target}の新規登録が完了しました。結果は一覧画面でご確認下さい。`
}

export const getUpdateCompleteMsg = (target: string): string => {
  return `${target}の更新が完了しました。`
}

export const getDeleteCompleteMsg = (target: string): string => {
  return `${target}の削除が完了しました。結果は一覧画面でご確認下さい。`
}

export const getSendCompleteMsg = (target: string): string => {
  return `${target}の送信が完了しました。\nなお、ご指定のメールアドレスに確認メールを送信しました。\n届いていない場合はメールアドレスにお間違いがないかをご確認の上、\n再度ご送信をお願いします。`
}
