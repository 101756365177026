import App from 'App'
import Page404 from 'pages/errors/404'

export const routerConfig = [
  {
    path: '/',
    element: <App />,
    // basename: '/reservations/',
    // NotFound時は共通ヘッダー/フッターを非表示とするため、レイアウトが設定される前のここで定義しておく。
    errorElement: <Page404 />,
    // 正常パスの場合は、表示するelementは定義せずに、pathのみを定義しておく。
    // (Not Foundとそれ以外を判定するのが目的であるため)
    // 実際の遷移先のコンポーネント(element)指定は、layout.tsx内で指定している。
    children: [
      // ログイン
      { path: '/login' },
      // パスワード変更
      { path: '/change-password' },
      // メール送信履歴
      { path: '/mail-send-history' },
      // 通知一覧
      { path: '/notifications' },
      { path: '/notifications/detail' },
      // 施設予約
      { path: '/reservations' },
      { path: '/reservations/daily' },
      { path: '/reservations/by-facility' },
      { path: '/reservations/detail' },
      // カレンダーダイアログ
      { path: '/calendar' },
      // オフィス管理
      { path: '/offices' },
      { path: '/offices/detail' },
      // 契約社管理
      { path: '/contractor' },
      { path: '/contractors' },
      // { path: '/contractors/detail' },
      // 施設管理
      { path: '/facilities' },
      { path: '/facilities/detail' },
      { path: '/facilities/suspends' },
      // ファイルアップロード
      { path: '/uploadFiles' },
      { path: '/uploadFile' },
      // ファイルダウンロード
      { path: '/downloadFiles' },
      { path: '/downloadFile' },
      // メールテンプレート
      { path: '/mailTemplates' },
      // お問合せ
      { path: '/inquiry' }
      // { path: '/inquiryConfirm' },
      // { path: '/inquiryNotify' }
    ]
  }
]
