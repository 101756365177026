import styles from 'components/mailTemplates/mailTemplate/PrecedentsForm.module.scss'
import tStyles from 'styles/utils/table.module.scss'

const PrecedentsForm = () => {
  return (
    <div className={tStyles.tableFrame}>
      <div className={tStyles.commonTable} style={{ width: '100%' }}>
        <div className={tStyles.headerRow}>
          <div className={`${tStyles.headerColumn} ${styles.col1}`}>#</div>
          <div className={`${tStyles.headerColumn} ${styles.col2}`}>記号</div>
          <div className={`${tStyles.headerColumn} ${styles.col3}`}>出力項目</div>
          <div className={`${tStyles.headerColumn} ${styles.col4}`}>表示内容</div>
        </div>
        <div className={tStyles.dataRow}>
          <div className={`${tStyles.dataColumn} ${styles.col1}`}>1</div>
          <div className={`${tStyles.dataColumn} ${styles.col2}`}>
            &lt;{'{'}email{'}'}&gt;
          </div>
          <div className={`${tStyles.dataColumn} ${styles.col3}`}>メールアドレス</div>
          <div className={`${tStyles.dataColumn} ${styles.col4}`}>
            お問い合わせ者のメールアドレス
          </div>
        </div>
        <div className={tStyles.dataRow}>
          <div className={`${tStyles.dataColumn} ${styles.col1}`}>2</div>
          <div className={`${tStyles.dataColumn} ${styles.col2}`}>
            &lt;{'{'}about{'}'}&gt;
          </div>
          <div className={`${tStyles.dataColumn} ${styles.col3}`}>お問い合わせ項目</div>
          <div className={`${tStyles.dataColumn} ${styles.col4}`}></div>
        </div>
        <div className={tStyles.dataRow}>
          <div className={`${tStyles.dataColumn} ${styles.col1}`}>3</div>
          <div className={`${tStyles.dataColumn} ${styles.col2}`}>
            &lt;{'{'}title{'}'}&gt;
          </div>
          <div className={`${tStyles.dataColumn} ${styles.col3}`}>お問い合わせの件名</div>
          <div className={`${tStyles.dataColumn} ${styles.col4}`}></div>
        </div>
        <div className={tStyles.dataRow}>
          <div className={`${tStyles.dataColumn} ${styles.col1}`}>4</div>
          <div className={`${tStyles.dataColumn} ${styles.col2}`}>
            &lt;{'{'}detail{'}'}&gt;
          </div>
          <div className={`${tStyles.dataColumn} ${styles.col3}`}>お問い合わせの詳細</div>
          <div className={`${tStyles.dataColumn} ${styles.col4}`}></div>
        </div>
        <div className={tStyles.dataRow}>
          <div className={`${tStyles.dataColumn} ${styles.col1}`}>5</div>
          <div className={`${tStyles.dataColumn} ${styles.col2}`}>
            &lt;{'{'}name{'}'}&gt;
          </div>
          <div className={`${tStyles.dataColumn} ${styles.col3}`}>名前</div>
          <div className={`${tStyles.dataColumn} ${styles.col4}`}>お問い合わせ者の氏名</div>
        </div>
        <div className={tStyles.dataRow}>
          <div className={`${tStyles.dataColumn} ${styles.col1}`}>6</div>
          <div className={`${tStyles.dataColumn} ${styles.col2}`}>
            &lt;{'{'}corpName{'}'}&gt;
          </div>
          <div className={`${tStyles.dataColumn} ${styles.col3}`}>会社名</div>
          <div className={`${tStyles.dataColumn} ${styles.col4}`}>お問い合わせ者の会社名</div>
        </div>
        <div className={tStyles.dataRow}>
          <div className={`${tStyles.dataColumn} ${styles.col1}`}>7</div>
          <div className={`${tStyles.dataColumn} ${styles.col2}`}>
            &lt;{'{'}tel{'}'}&gt;
          </div>
          <div className={`${tStyles.dataColumn} ${styles.col3}`}>電話番号</div>
          <div className={`${tStyles.dataColumn} ${styles.col4}`}>お問い合わせ者の電話番号</div>
        </div>
      </div>
    </div>
  )
}

export default PrecedentsForm
